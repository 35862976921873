
import slide1 from '../../images/apple_icon/slide1.jpg'
import slide2 from '../../images/apple/iphone16pro-full.jpg'
import slide4 from '../../images/apple/iphone16-full.jpg'
import slide5 from '../../images/apple/whatch10-full.jpg'
import slide3 from '../../images/apple_icon/slide3.jpg'

import slide1media from '../../images/apple_icon/slider1Media.jpg'
import slide4media from '../../images/apple/iphone16-media.jpg'
import slide5media from '../../images/apple/whatch10-media.jpg'
import slide2media from '../../images/apple/iphone16pro-media.jpg'
import { Link } from 'react-router-dom'
import slide3media from '../../images/apple_icon/slide3Media.jpg'
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import TestCard from './TestCard';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import React from 'react'
import './home.scss'
// images
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {FaBars,FaTimes} from 'react-icons/fa';
import Helmet from 'react-helmet'
import { userSelector, getProductsSelector, favoritesSelector,  setAuth, setUser, setType, setCategories, setOpensFilter,setAllFilters, authSelector,setCategoryFilter, categoriesSelector } from '../../components/slices/SlicesStore';


function BanerHomePage({data}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Swiper
    
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={0}
      navigation={true}
      slidesPerView={1}
      loop={true}
      keyboard={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        1025 : {
          slidesPerView : 1,
          spaceBetween : 30
        }

    }}
    >

      <SwiperSlide>

        <div onClick = {() => {navigate('/catalog');dispatch(setType("iPhone"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}}className="baner-full-slide">
                <img src={slide2} alt="" />
            </div>
            <div onClick = {() => {navigate('/catalog');dispatch(setType("iPhone"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}}className="baner-media-slide">
                <img src={slide2media} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div onClick = {() => {navigate('/catalog');dispatch(setType("iPhone"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}} className="baner-full-slide">
                <img src={slide4} alt="" />
            </div>
            <div onClick = {() => {navigate('/catalog');dispatch(setType("iPhone"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}} className="baner-media-slide">
                <img src={slide4media} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div onClick = {() => {navigate('/catalog');dispatch(setType("Watch"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}} className="baner-full-slide">
                <img src={slide5} alt="" />
            </div>
            <div onClick = {() => {navigate('/catalog');dispatch(setType("Watch"));dispatch(setAllFilters([]));dispatch(setOpensFilter([]));dispatch(setCategoryFilter("Apple"))}}className="baner-media-slide">
                <img src={slide5media} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="baner-full-slide">
                <img src={slide3} alt="" />
            </div>
            <div className="baner-media-slide">
                <img src={slide3media} alt="" />
        </div>
      </SwiperSlide>
      
    </Swiper>
  )
}

export default BanerHomePage
